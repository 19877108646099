<template>
  <div
    class="contactus-1 bg-default"
    :style="`background-color: rgb(23, 43, 77)`"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-lg-5 col-md-5 d-flex justify-content-center flex-column"
        >
          <h2 class="title text-white">Get in Touch</h2>
          <h4 class="description text-white">
            You need more information? Check what other persons are saying about
            our product. They are very happy with their purchase.
          </h4>
          <div class="info info-horizontal">
            <div
              class="icon icon-shape shadow rounded-circle text-white bg-myprimary"
            >
              <i class="fa fa-map-marker"></i>
            </div>
            <div class="description">
              <h4 class="info-title text-white">Find us at the office</h4>
              <p class="description ml-3 text-white">
                R. Agostinho da Silva Rocha 945,<br />
                4475-451 Nogueira Maia Portugal,<br />
              </p>
            </div>
          </div>
          <div class="info info-horizontal">
            <div
              class="icon icon-shape shadow rounded-circle text-white bg-myprimary"
            >
              <i class="fa fa-phone"></i>
            </div>
            <div class="description">
              <h4 class="info-title text-white">Give us a ring</h4>
              <p class="description ml-3 text-white">
                <a
                  class="text-white"
                  href="tel:+351 22 961 77 00"
                  target="_blank"
                  >+351 22 961 77 00</a
                >
                <br />
                <a
                  class="text-white"
                  href="tel:+351 22 493 33 24"
                  target="_blank"
                  >+351 22 493 33 24</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-7 ml-auto mr-auto mt-2">
          <card headerClasses="text-center">
            <template slot="header" class="text-center">
              <h4 class="card-title">Contact Us</h4>
            </template>
            <template slot="body">
              <div v-if="message_sent">
                <base-alert dismissible icon="fa fa-check" type="success"
                  ><div slot="text">
                    Your message has been sent.
                  </div></base-alert
                >
              </div>
              <validation-observer ref="observer">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <validation-provider
                        name="First Name"
                        rules="required|min:3"
                        v-slot="{ errors }"
                      >
                        <label>First name</label>
                        <base-input
                          :error="errors[0]"
                          v-model="message.first_name"
                          placeholder="First name"
                        ></base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-6 pl-2">
                    <div class="form-group">
                      <validation-provider
                        name="Last Name"
                        rules="required|min:3"
                        v-slot="{ errors }"
                      >
                        <label>Last name</label>
                        <base-input
                          :error="errors[0]"
                          v-model="message.last_name"
                          placeholder="Last name"
                        ></base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <validation-provider
                    name="Email address"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label>Email address</label>
                    <base-input
                      :error="errors[0]"
                      placeholder="Email address"
                      v-model="message.email"
                    ></base-input>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <validation-provider
                    name="Message"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label>Your message</label>
                    <textarea
                      name="message"
                      class="form-control"
                      id="message"
                      rows="6"
                      v-model="message.message"
                    ></textarea>
                    <small>
                      <span class="text-danger" v-if="errors[0]">{{
                        errors[0]
                      }}</span>
                    </small>
                  </validation-provider>
                </div>
                <div class="row">
                  <div class="col-md-6 ml-auto">
                    <base-button
                      @click="sendMessage()"
                      nativeType="submit"
                      type="login"
                      class="pull-right"
                      >Send Message</base-button
                    >
                  </div>
                </div>
              </validation-observer>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: { ValidationProvider, ValidationObserver },
  data: () => ({
    message: {},
    message_sent: false
  }),
  methods: {
    sendMessage() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.$axios.post("contact-us/", this.message).then(res => {
          if (res.status == 201) {
            this.message_sent = true;
            setTimeout(() => {
              this.message_sent = false;
            }, 3000);
            this.message = {};
            this.$refs.observer.reset();
          }
        });
      });
    }
  }
};
</script>
<style></style>
